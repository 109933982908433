@import "../../../../App.scss";

.faq-block {
	width: 100%;
	margin: 0 auto;
	.card {
		background: $clr-white;
		box-shadow: 0 0 5px 5px #f8f8f8;
		margin-bottom: 1.5rem;
		border: 0;
		.card-header {
			padding: 1rem 1.25rem;
			background-color: transparent;
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
			transition: all .2s ease;
			button {
				color: $clr-black-light;
				text-transform: none;
				font-size: clamp(1rem, 2.5vw, 1.3rem);
				font-weight: normal;
				background: transparent;
				border: 0;
				padding: 0;
				display: flex;
				width: 100%;
				padding-right: 1.3rem;
				text-align: left;
				@include transition(all 0.2s ease-in-out);
				&:hover {
					text-decoration: underline;
					color: $clr-black;
				}
				&.active {
					color: $clr-black;
				}
			}
			svg {
				width: clamp(1rem, 2.5vw, 1.2rem);
			}
		}
		.arrow-up, .arrow-down {
			position: absolute;
			right: 1.2rem;
			pointer-events: none;
		}
		.arrow-up {
			display: none;
			&.active {
				display: block;
			}
		}
		.arrow-down {
			display: none;
			&.active {
				display: block;
			}
		}
		.card-body {
			padding: 1.5rem 1.5rem;
			background-color: #F6F6F6;
			line-height: 2rem;
			font-size: clamp(0.8rem, 2.2vw, 1.1rem);
			a {
				font-weight: 600;
				color: $clr-blue-light;
				&:hover {
					color: $clr-blue-medium;
				}
			}
		}
	}
	.end-note {
		margin: 3rem 0;
		font-weight: normal;
		font-size: 1.1rem;
		a {
			font-weight: 600;
			color: $clr-blue-light;
			&:hover {
				color: $clr-blue-medium;
			}
		}
	}
}

