@import '../../../../App.scss';
.partnerWrapper {
  a:not(.btn) {
    color: #007bff;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #0056b3
    }
  }
  .btn {
    text-transform: none;
  }
  img {
    &.loading {
      width: 50px;
    }
  }
  section {
    margin: 15px 0;
  }
  .section-head {
    margin-bottom: 20px;
  }
  .partner-list {
    .partner-row {
      list-style: none;
      @include flexbox;
      @include align-items(center);
      flex-wrap: wrap;
      li  {
        padding: 10px 7px 10px 10px;
        font-weight: 500;
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(center);
        line-height: normal;
        width: calc(50% - 10px);
        border-radius: 5px;
        transition: all 0.1s ease-in-out;
        .partner-actions {
          display: flex;
          align-items: center;
        }
        img {
          width: 18px;
          cursor: pointer;
          opacity: 0.7;
          margin: 0 6px;
          &.resend {
            margin-right: 10px;
          }
        }
        &:nth-child(odd) {
          margin-right: 20px;
        }
        a {
          color: #212121;
          font-weight: 500;
          width: 100%;
          &:hover {
            color: $clr-black;
          }
        }
        &:hover {
          background-color: #F6F6F6;
          a {
            color: $clr-black;
          }
          img {
            &:hover {
              cursor: pointer;
              opacity: 1;
            }
          }
        }
        .status {
          text-transform: capitalize;
          &.pending {
            color: $clr-blue-light;
          }
          &.accepted {
            color: $clr-green-dark;
          }
          &.rejected {
            color: $clr-red;
          }
        }
      }
    }
  }
}
.form-group {
  .partners {
    .checkmark {
      margin-top: 4px;
    }
  }
}
.invalid__control {
  height: 48px;
  border-radius: 8px !important;
  border-color: $clr-red !important;
}
small {
  &.error {
    color: $clr-red;
    display: block;
  }
}
form {
  .form-control {
    &.invalid {
      background-image: none !important;
      border-color: $clr-red !important;
      padding-right: .75rem !important;
      &:focus, &:hover {
        box-shadow: none !important;
        border: 1px solid $clr-red !important;
        background-color: transparent !important;
      }
    }
  }
}
.custom-invalid-feedback {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: $clr-red;
}