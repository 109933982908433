@import '../../../../App.scss';

section {
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 0;
  }
}
.artist-section-control {
  .btn {
    text-transform: inherit;
    &.submit {
      img {
        width: 20px;
      }
    }
  }
  a:not(.btn) {
    color: #007bff;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #0056b3
    }
  }
  .section-content {
    .tax-note {
      display: block;
    }
    .loading {
      width: 50px;
    }
    .bgSecondVersion {
      margin-top: 0px !important;
    }
    .section-body {
      .parallel-info {
        @include flexbox;
        @include justify-content(flex-start);
        margin-bottom: 25px;
        line-height: normal;
        label {
          min-width: 200px;
          font-weight: 700;
          color: #212121;
          font-size: 16px;
          text-transform: capitalize;
          text-align: left;
          margin-right: 25px;
          img {
            width: 25px;
            margin-left: 10px;
            vertical-align: middle;
          }
        }
        .info-ans {
          color: #212121;
          font-size: 16px;
          line-height: 1.3;
          transition: all 0.2s ease-in-out;
          img {
            width: 230px;
            border-radius: 7px;
          }
          .bg-content {
            text-transform: none;
            a {
              font-weight: 600;
            }
            .artist-name {
              text-transform: capitalize;
            }
          }
          &.additional-elements {
            @include flexbox;
            flex-wrap: wrap;
            place-items: flex-end;
            img {
              &:last-child {
                margin-right: 0;
              }
            }
            .additional-image {
              width: 180px;
              margin: 0 15px 15px 0;
            }
          }
          .loading {
            &.tax-form {
              width: 22px;
            }
          }
          .tax-form-link {
            &:hover {
              cursor: pointer;
            }
          }
          &.genres {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .genre {
              background-color: #eee;
              border-radius: 2rem;
              padding: .3rem .7rem;
              font-size: 80%;
              margin-bottom: .5rem;
              margin-right: .3rem;
            }
          }
        }
        &.social {
          .info-ans {
            p {
              margin-bottom: 5px;
              text-transform: none;
              font-weight: 600;
            }
          }
           
        }
      }
    }
    .custom-file-input {
      &:hover {
        cursor: pointer;
      }
    }
    .invalid__control {
      border-color: $clr-red;
    }
    small {
      &.error {
        color: $clr-red;
      }
    }
  }
  .bg-content {
    padding: 20px 20px;
    margin: 0;
  }
  form {
    .row {
      margin-bottom: 15px;
      .form-label {
        font-weight: 600;
        margin-bottom: 10px;
        img {
          width: 25px;
          margin-left: 10px;
          vertical-align: top;
        }
      }
      .custom-invalid-feedback {
        width: 100%;
        margin-top: .25rem;
        font-size: 80%;
        color: #dc3545;
      }
      .preview {
        width: 180px;
        border-radius: 7px;
        margin-top: 10px;
      }
      .country-select-container-header,
      .pro-select-container-header {
        z-index: 3;
        .invalid__control {
          height: calc(1.5em + .75rem + 2px);
          border-radius: .25rem !important;
        }
        .country-select-header__control,
        .pro-select-header__control {
          border-radius: .25rem;
        }
      }
      .country-select-container-header {
        z-index: 4;
      }
    }
    .form-control {
      &.invalid {
        border-color: $clr-red;
        &:hover,
        &:focus {
          box-shadow: none;
          border: 1px solid $clr-red;
          background-color: transparent;
        }
        &:valid {
          background-image: none;
          padding-right:  0.75rem;
        }
      }
    }
  }
}

.contact {
  .section-body {
    span {
      display: block;
    }
  }
}

.w-custom-bg-content {
  max-width: 475px;
  width: 100%;
}

.file-display-container {
  .file-name {
    text-overflow: ellipsis;
    max-width: 260px;
    white-space: nowrap;
    overflow: hidden;
  }
}

.form-control {
  color: #212121;
}

.artist-name-bonding {
  @include flexbox;
  @include justify-content(flex-start);
  @include align-items(center);
} 



@media #{$phone-landscape} {
  .artist-section-control {
    .section-content {
      .section-body {
        .parallel-info {
          @include flex-direction(column);
          label {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}