@import '../../../../App.scss';
.musicWrapper {
  .loading {
    width: 50px;
  }
  section {
    margin: 15px 0;
  }
  .section-head {
    margin-bottom: 20px;
  }
  .music-playlist {
    .music-row {
      list-style: none;
      @include flexbox;
      @include align-items(center);
      flex-wrap: wrap;
      .notes-container {
        margin-left: 3px;
      }
      li  {
        padding: 10px 7px 10px 10px;
        font-weight: 500;
        @include flexbox;
        @include justify-content(space-between);
        @include align-items(flex-start);
        line-height: normal;
        width: calc(50% - 10px);
        border-radius: 5px;
        transition: all 0.1s ease-in-out;
        .album-actions {
          display: flex;
          align-items: center;
        }
        img {
          width: 18px;
          cursor: pointer;
          opacity: 0.7;
          margin: 0 6px;
          &.disable-delete {
            width: 23px;
            margin-left: 3px;
            margin-right: 3px;
            &:hover {
              cursor: inherit;
            }
          }
        }
        &:nth-child(odd) {
          margin-right: 20px;
        }
        a {
          width: 100%;
          &:hover {
            color: $clr-black;
          }
        }
        &:hover {
          background-color: #F6F6F6;
          img {
            &:hover:not(.disable-delete) {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.notes-container {
  line-height: inherit;
}
/***************** POPUP styling *********************/
.customArtistModal,
.customArtistModal-lg {
  z-index: 1041;
  .modal-dialog {
    max-width: 700px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    .modal-content {
      border-radius: 15px;
      overflow: hidden;
    }
    .modal-header {
      padding: 1.5rem 1rem;
      .modal-title {
        margin: 0 auto;
        font-weight: 600;
        font-size: 28px;
      }
      .close {
        display: none;
      }
    }
    .modal-body {
      padding: 40px 15px 25px;
      border-bottom: 1px solid #dee2e6;
      .create-album-modal-container,
      .music-modal-container,
      .publisher-modal-container,
      .collabortor-modal-container {
        max-width: 500px;
        width: 95%;
        margin: 0 auto;
        .form-control {
          &:not(textarea) {
            height: 48px;
          }
          border-radius: 8px;
          &:focus {
            transition: all 0.3s ease-in-out;
            box-shadow: 0 0 5px #c0d72d;
          }
        }
        .progress-bar-container {
          display: block;
          position: relative;
          padding: 30px 0;
          .response-note {
            display: block;
            position: relative;
            width: 100%;
            top: 30px;
            text-align: center;
            img {
              width: 35px;
            }
          }
        }
      }
      .react-select-popup__control {
        min-height: 48px;
        border-radius: 8px;
      }
      .custom-control-input {
        z-index: 1;
        opacity: 1;
      }
      .custom-file {
        height: 48px;
        input[type="file"] {
          height: inherit;
        }
        .custom-file-label {
          height: inherit;
          line-height: normal;
          @include flexbox;
          @include justify-content(space-between);
          @include align-items(center);
          border-radius: 8px;
          &:after {
            content: attr(data-browse);
            height: inherit;
            line-height: calc(48px - 12px);
          }
        }
        .custom-file-input {
          &:focus {
            & ~ .custom-file-label {
              transition: all 0.3s ease-in-out;
              box-shadow: 0 0 5px #c0d72d;
              border: 1px solid #c0d72d;
            }
          }
        }
        &.invalid {
          .custom-file-label {
            border: 1px solid $clr-red;
          }
        }
      }
    }
    .modal-footer {
      border: 0;
      padding: 0;
      height: 70px;
      button {
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 1px;
        @include flex(1);
        border-radius: 0;
        border: 0;
        height: inherit;
        margin: 0;
        transition: all 0.3s ease-in-out;
        &.btn-outline-light {
          &:hover {
            background-color: #F6F6F6;
          }
        }
        &.as-tertiary-modal-btn {
          background-color: $clr-blue-light;
          color: #fff;
          &:hover {
            opacity: 0.85;
          }
        }
      }
      .submit {
        img {
          width: 20px;
        }
      }
      .btn-outline-light:disabled {
        color: $clr-grey;
      }
    }
  }
  &.closeOn {
    .modal-header {
      .modal-title {
        padding: 0px 40px;
      }
      .close {
        display: inline-block;
        background: url(../../../../images/close-modal.svg) no-repeat;
        vertical-align: middle;
        background-size: 30px;
        width: 30px;
        height: 30px;
        margin: 0;
        padding: 0;
        top: 15px;
        right: 15px;
        opacity: 0.7;
          span[aria-hidden="true"] {
          display: none;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  &.customArtistModal-lg {
    .modal-dialog {
      max-width: 850px;
      width: 95%;
      .modalBodyInner {
        max-width: 600px;
      }
    }
  }
}

.music-modal-container {
  .custom-file {
    & > .custom-file-label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block !important;
      line-height: 33px !important;
      padding-right: 130px;
    }
  }
}

/**************** Album styling *****************/
.albumsWrapper {
  .loading {
    width: 50px;
  }
  img {
    &.loading {
      width: 20px;
    }
  }
  section {
    margin: 15px 0;
  }
  .section-content {
    .section-head {
      margin-bottom: 25px;
      .sec-controls {
        .delete {
          &:hover {
            color: #fff;
            border-color: $clr-red;
            background-color: $clr-red;
            box-shadow: none;
          }
        }
      }
    }
  }
  .artwork-images-sec {
    @include flexbox;
    flex-wrap: wrap;
    .artwork-image {
      flex: 0.1 1 150px;
      height: auto;
      border-radius: 7px;
      margin: 0 15px 0 0;
      overflow: hidden;
      transition: all 0.2s ease-in-out;
      img {
        width: 100%;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &.edit {
    .section-head {
      margin-bottom: 40px;
    }
    .row {
      margin-bottom: 25px;
    }
    .preview {
      width: 180px;
      margin-top: 10px;
      border-radius: 7px;
    }
  }
}

.track-wrapper {
  @include flexbox;
  @include flex-direction(column);
  .trackrow {
    @include flex(1 1 100%);
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    background-color: #f6f6f6;
    padding: 10px 10px;
    border-radius: 7px;
    margin-bottom: 10px;
    .track-title {
      @include flex(1 1 20%);
      @include flexbox;
      @include justify-content(flex-start);
      @include align-items(center);
      line-height: normal;
      p {
        margin: 0;
      }
      em {
        font-size: 11px;
      }
      &:before {
        content: '';
        background: url(../../../../images/music.svg) no-repeat;
        display: inline-block;
        vertical-align: middle;
        background-size: 17px;
        width: 17px;
        height: 20px;
        margin-right: 20px;
      }
    }
    .track-player {
      @include flex(1 1 25%);
      .track {
        padding: 0 10px 0;
        display: grid;
        a {
          &:hover {
            color: $clr-black;
            font-weight: 600;
          }
        }
        em {
          font-size: 11px;
        }
      }
    }
    .track-writter{
      @include flex(1 1 15%);
      small {
        display: block;
        &.pending, &.rejected {
          color: $clr-red;
        }
        &.accepted {
          color: $clr-green-dark;
        }
      }
    }
    .track-publisher{
      @include flex(1 1 15%);
    }
    .track-status{
      @include flex(1 1 15%);
      text-transform: capitalize;
    }
    .track-edit{
      @include flex(1 1 10%);
      @include flexbox;
      @include align-items(center);
      a {
        width: 18px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 4%;
        margin-right: 6%;
        opacity: 0.5;
        transition: all 0.3s ease-in-out;
        img {
          width: 100%;
        }
        &.track-action:hover {
          opacity: 1;
          cursor: pointer;
        }
        &.disabled {
          img {
            width: 20px;
            margin-left: -2px;
          }
        }
      }
      .notes-container {
        display: inline-block;
        margin-left: 8%;
        width: 23px;
        img {
          width: 18px;
          margin-top: 6px;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.head-row {
      background: none;
      font-weight: 700;
      text-transform: capitalize;
      margin-bottom: 0;
      .track-title {
        &:before {
          background: none;
        }
      }
    }
    &.body-row {
      .track-player {
        padding: 0;
      }
    }
    & > div {
      padding: 0 10px 0;
    }
  }
}
.flexibleRowContain {
  padding-bottom: 20px;
  .flexibleRow {
    .col {
      min-width: 100%;
      max-width: 100%;
      background-color: #f3f4f5;
      padding: 7px 12px;
      border-radius: 7px;
      margin-bottom: 7px;
      .paraElements {
        @include flexbox();
        @include align-items(center);
        label {
          margin-bottom: 0;
          flex: 1;
        }
        input[type="number"] {
          flex: 0 1 28%;
          height: 38px;
          line-height: 38px;
        }

      }
    }
  }
  .form-group {
    margin-bottom: 0px;
  }
}

@media #{$phone-landscape} {
  .track-wrapper {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    .trackrow {
      width: 900px;
    }
  }
}

@media #{$phone-portrait} {
  .customArtistModal-lg,
  .customArtistModal {
    .modal-dialog {
      .modal-footer {
        @include flexbox;
        @include flex-direction(column);
        height: auto;
        width: 100%;
        button {
          padding: 15px 10px;
          width: 100%;
          font-size: 16px;
        }
      }
    }
    &.closeOn {
      .modal-header {
        .modal-title {
          padding: 0px 30px;
          font-size: 25px;
          text-align: center;
          line-height: normal;
        }
      }
    }
  }
}

.pub-percentage::-webkit-inner-spin-button, 
.pub-percentage::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
