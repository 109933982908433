@import '../../App.scss';

.notes-container {
  width: 32px;
  line-height: 38px;
  cursor: pointer;
  position: relative;
  margin-left: 10px;
  .notes-badge {
    min-width: 20px;
    height: 20px;
    background: #b7ce28;
    color: #fff;
    padding: 0 3px;
    text-align: center;
    line-height: 18px;
    display: inline-block;
    border-radius: 50px;
    font-size: 13px;
    font-weight: 600;
    position: absolute;
    top: -10px;
    right: -5px;
  }
  img {
      width: 100%;
  }
}

.notes-modal {
  &.customArtistModal {
    .modal-dialog {
      .modal-title {
        text-transform: capitalize;
      }
      .modal-body {
        padding-bottom: 0;
        .loading {
          font-size: 20px;
          text-align: center;
          padding-bottom: 40px;
          img {
            width: 50px;
          }
        }
        .notes-modal-container {
          width: 95%;
          margin: 0 auto;
          .existing-notes {
            max-height: 300px;
            overflow-y: auto;
            margin-bottom: 50px;
            .note-item {
              margin-bottom: 20px;
            }
            .note {
              padding: 20px 20px;
              background-color: #f6f6f6;
              border-radius: 12px;
              max-width: 90%;
              small {
                text-transform: capitalize;
              }
            }
            img {
              margin-top: 10px;
              width: 230px;
              border-radius: 7px;
            }
          }
          .typeSec {
            display: grid;
            grid-template-columns: 1fr 48px;
            column-gap: 15px;
            @include align-items(flex-end);
            textarea {
              &.form-control {
                border-radius: 48px;
                background-color: #f6f6f6;
                resize: none;
                height: 48px;
                padding: 15px 20px;
                line-height: normal !important;
                overflow: hidden;
                &:invalid {
                  background-position: top calc(.675em + .1875rem) right calc(.375em + .1875rem);
                }
                &:valid {
                  background-position: top calc(.675em + .1875rem) right calc(.375em + .1875rem);
                }
              }
            }
            .controlArea {
              margin-bottom: 15px;
              label {
                background-color: #f6f6f6;
                min-width: 48px;
                min-height: 48px;
                border-radius: 48px;
                text-align: center;
                line-height: 48px;
                @include transition(all 0.3s ease-in-out);
                svg {
                  width: 18px;
                  transform: rotate(-45deg);
                }
                img {
                  width: 25px;
                }
                &:hover {
                  cursor: pointer;
                  background-color: #eee;
                }
              }
            }
          }
        }
      }
    }
  }
  .was-validated {
    textarea {
      &.form-control {
        &:invalid {
          border-color: $clr-red-light;
        }
      }
    }

  }
}
