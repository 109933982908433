@import '../../../../App.scss';
.agreementWrapper {
  section {
    margin: 15px 0;
  }
  .agreementBody {
    max-width: 930px;
    width: 100%;
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
    .loading {
      width: 50px;
    }
    .primary-btn {
      text-transform: none;
      &.accepted {
        background-color: $clr-green-dark !important;
        border-color: $clr-green-dark !important;
        color: white !important;
        &:hover, &:active {
          background-color: $clr-green-dark !important;
          border-color: $clr-green-dark !important;
          color: white !important;
          opacity: .8;
        }
      }
      &.rejected {
        background-color: $clr-red-light !important;
        border-color: $clr-red-light !important;
        color: white !important;
        &:hover, &:active {
          background-color: $clr-red-light-extra !important;
          border-color: $clr-red-light-extra !important;
          color: white !important;
        }
      }
    }
    .agreementType {
      text-transform: capitalize;
      font-weight: 700;
      margin-bottom: 0;
    }
    .status-timestamp {
      span {
        text-transform: capitalize;
        font-weight: 600;
        padding: 4px 10px;
        color: white;
        border-radius: 4px;
        &.accepted {
          background-color: $clr-green-dark;
        }
        &.rejected {
          background-color: $clr-red;
        }
      }
    }
  }
  a:not(.btn) {
    color: #007bff;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #0056b3
    }
  }

  .agreementContent {
    border: 1px solid #dee2e6;
    border-radius: 7px;
    padding: 20px;
    max-height: 200px;
    overflow-y: auto;
    ol {
      margin-left: 15px;
      font-weight: 600;
    }
    ul {
      li {
        margin-left: 20px;
        ol {
          padding-top: 10px;
          font-weight: normal;
          li {
            margin-top: 10px;
          }
        }
      }
    }
  }
  .agreementContentController {
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    padding: 12px 0;
  }
}

@media #{$phone-portrait} {
  .agreementWrapper {
    .agreementBody {
      .agreementType {
        font-size: 25px;
      }
    }
    .agreementContent {
      padding: 12px 10px;
    }
    .agreementContentController {
      flex-direction: inherit;
      & > * {
        margin: 7px 0;
      }
    }
  }
}