@import "../../App.scss";

.login-block {
  max-width: 40rem;
  width: 100%;
  .btn {
    &.download {
      background-color: transparent;
      border: 1px solid $clr-grey;
      text-transform: none;
    }
    img {
      float: right;
    }
  }
  .form {
    .form-group {
      &.invalid {
        .form-control{
          border-color: $clr-red;
          background-image: none;
          &:focus {
            box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
          }
        }
      }
    }
    small {
      &.invalid {
        color: $clr-red;
      }
    }
    &.agreement {
      .agreement-form-container {
        padding: 2rem 0;
        &:not(:last-of-type) {
          border-bottom: 1px solid $clr-grey;
        }
        .agreement-type {
          text-transform: capitalize;
        }
      }
      .agreement-container {
        border: 1px dashed $clr-grey;
        padding: 1rem;
        max-height: 50vh;
        overflow-x: scroll;
        ol {
          margin-left: 15px;
          font-weight: 600;
        }
        ul {
          li {
            margin-left: 20px;
            ol {
              padding-top: 10px;
              font-weight: normal;
              li {
                margin-top: 10px;
              }
            }
          }
        }
      }
      button {
        &.reject {
          background-color: $clr-red-light !important;
          border-color: $clr-red-light !important;
          &:hover, &:focus {
            background-color: $clr-red-light-extra !important;
            border-color: $clr-red-light-extra !important;
          }
        }
        &.faqs {
          background-color: $clr-grey !important;
          border-color: $clr-grey !important;
          img {
            height: 19px;
          }
          &:hover, &:focus {
            background-color: $clr-grey-border !important;
            border-color: $clr-grey-border !important;
          }
        }
      }
    }
  }
}
