@import '../../../../App.scss';

.invite-contain-head {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  font-weight: 700;
  .invite-artist {
    flex: 1 1 50%;
  }
  .access-type {
    flex: 1 1 25%;
  }
  .agreements {
    flex: 1 1 25%;
  }
}
.agreementBody {
  .pt-4 {
    padding-top: 0 !important;
    &:first-of-type {
      margin-top: 0;
    }
  }
}
.invite-contain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F6F6F6;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 7px;
  .invite-artist {
    flex: 1 1 50%;
  }
  .access-type {
    flex: 1 1 25%;
    text-transform: capitalize;
  }
  .invite-status {
    flex: 1 1 25%;
    .dropdown-toggle {
      padding-left: 0;
      padding-right: 0;
      text-transform: capitalize;
      color: hsl(0deg, 0%, 10%);
      font-weight: normal;
      &:hover {
        color: hsl(0deg, 0%, 50%);;
      }
      &::after {
        vertical-align: 0.155em;
      }
    }
    .dropdown-menu {
      min-width: 8rem;
      a {
        font-weight: normal;
        color: hsl(0deg, 0%, 10%);
        &.dropdown-item {
          font-size: 14px;
          &.active,
          &:active {
            background-color: #eee;
          }
        }
      }
    }
  }
  .custom-switch {
    flex: 1 1 25%;
  }
  .custom-control-label {
    &:hover {
      cursor: pointer;
    }
  }
  .custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    opacity: 0.5;
    border-color: #28a745;
    background-color: #28a745;
  }
  .custom-control-input:checked~.custom-control-label::before {
    border-color: #28a745;
    background-color: #28a745;
  }
  .custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #28a745;
  }
  .custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 0.2rem hsl(68deg, 80%, 55%);
  }
}

@media #{$phone-portrait} {
  .section-head {
    h2 {
      font-size: 25px;
    }
  }
  .invite-contain-head {
    padding: 10px 10px;
    .invite-artist {
      flex: 1 1 35%;
    }
    .agreements {
      padding-left: 10px;
    }
  }
  .invite-contain {
    padding: 7px 10px;
    .invite-artist {
      flex: 1 1 35%;
    }
    .invite-status {
      padding-left: 10px;
    }
  }
}