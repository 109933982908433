@import "src/App.scss";

header {
  background: #1a1a1a;
  .custom-nav {
    max-width: 1820px;
    width: 100%;
    margin: 0 auto;
    padding-top: 24px;
    padding-bottom: 24px;
    .navbar-toggler {
      border: 0;
      padding: 0;
      &:focus {
        outline: none;
      }
      .navbar-toggler-icon {
        background: url(../../../../images/menu-dots.svg) no-repeat;
        width: 7px;
      }
    }
    .navbar-nav {
      align-items: center;
      .nav-link {
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        margin-left: 60px;
        &:first-child {
          margin-left: 0;
        }
        &.profile-pic {
          max-width: 64px;
          max-height: 64px;
          height: 64px;
          padding: 0;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100px;
          }
        }
      }
      .artist-dropdown {
        .dropdown-menu {
          min-width: 8rem;
        }
        margin-left: 40px;
        a {
          display: flex;
          align-items: center;
        }
      }
    }
    .dropdown-item {
      &.active, &:active {
        background-color: $clr-green;
      }
    }
    .navbar-text {
      color: #ffffff;
      margin-left: 60px;
      font-weight: 600;
      font-size: 20px;
    }
  }
}
.not-for-desktop {
  display: none;
}

.customArtistModal {
  &.choose-artist-modal {
    .modal-dialog {
      max-width: 500px;
      .modal-content {
        .modal-body {
          padding: 40px 15px 40px;
          .choose-artist-modal-container {
            label {
              margin-bottom: 1rem;
            }
            .dropdown-menu {
              padding: 0;
              .dropdown-item {
                padding: 8px;
                &.active, &:active {
                  background-color: $clr-green-bright;
                  color: white;
                }
              }
            }
          }
        }
      }

    }
  }
}

@media #{$desktop-start} {
  header {
    .custom-nav {
      .navbar-text {
        margin-left: 20px;
      }
    }
  }
}


@media #{$mid-point-tablet} {
	header {
    .custom-nav {
      padding: 15px 1rem;
      .logo-brand {
        @include flex(1);
        text-align: center;
      }
      .not-for-desktop {
        display: block;
        width: 25px;
        img {
          width: 100%;
        }
      }
      .navbar-nav {
        @include flex-direction(row);
        .nav-link {
          @include flex(1);
          margin: 0;
          img {
            width: 20px;
            margin: 0 auto;
            &.profile-img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      .navbar-collapse {
        margin: 0 -15px;
        position: relative;
        bottom: -15px;
        z-index: 999;
        & > .navbar-nav {
          border-top: 1px solid #fff;
          & > * {
            flex: 1;
            border-right: 1px solid #fff;
            padding: 15px;
            min-width: 20%;
            height: 60px;
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);
            &:last-child {
              border: 0;
            }
          }
          .choose-artist-mobile {
            padding: 0;
            text-align: center;
            a {
              height: inherit;
              line-height: 42px;
            }
          }
        }
      }
      .navbar-text {
        padding: 0;
        line-height: 1;
        display: block;
        position: absolute;
        font-size: 13px;
        transform: translateX(-50%);
        left: 50%;
        top: 53px;
      }
    }
  }
}

.choose-artist-select {
  height: 48px;
  border-radius: 8px;
  a {
    padding: 0;
    @include flexbox;
    @include justify-content(space-between);
    @include align-items(center);
    height: 100%;
    &:hover {
      color: #212121;
    }
    &:after {
      margin-top: 0;
    }
    & + .dropdown-menu {
      padding: 10px;
      top: 5px !important;
      width: 95%;
      max-height: 110px;
      overflow-x: hidden;
      overflow-y: auto;
      & > a {
        height: auto;
      }
    }
  } 
}

.popup-btn-wrapper {
  padding-top: 7px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
}

@media #{$phone-portrait} {
  header {
    .custom-nav {
      .not-for-desktop {
        width: 20px;
      }
      .navbar-brand {
        img {
          width: 165px;
        }
      }
    }
    .navbar-text {
      top: 51px;
    }
  }
}